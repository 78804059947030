/** Requires apache running */
const devConfig = {
  api: 'http://calc-backend.localhost/',
};

/** Requires apache running in build folder / on production server */
const prodConfig = {
  api: '/',
};

export default process.env.NODE_ENV === 'production' ? prodConfig : devConfig;