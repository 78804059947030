import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import Login from './Login';

/** ASAP color mode switch to avoid FOUC */
const colorMode = localStorage.getItem('mode') || 'light';
const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
document.documentElement.classList.add(colorMode === 'auto' && systemDark ? 'dark' : colorMode);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>
);
