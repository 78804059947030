import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import env from './env';
import Loading from './components/loading/Loading';
// Styles & assets
import './Login.scss';
import { ReactComponent as VisibleSVG } from './assets/icons/visible.svg';
import { ReactComponent as HiddenSVG } from './assets/icons/hidden.svg';

// Lazy load App after authentication
const App = lazy(() => import('./components/App'));

export default function Login() {

  /** Sign-in token */
  const TOKEN = localStorage.getItem('signinToken');
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  /** Form */
  const [label, setLabel] = useState<string>('Zadejte přístupové heslo');
  const [isError, setIsError] = useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  /** Disables inputs */
  const [processing, setProcessing] = useState<boolean>(false);

  /** Show App */
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);

  /** Check login token validity */
  useEffect(() => {
    fetch(env.api + `checkToken.php?token=${TOKEN}&t=${Date.now()}`).then(r => r.json()).then(response => {
      setIsTokenChecked(true);
      if (response.success) {
        setLoggedIn(true);
      } else {
        passwordRef.current?.focus();
      }
    });
  }, [TOKEN]);

  /** Reset label & assign value */
  function handlePassChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsError(false);
    setLabel('Zadejte přístupové heslo');
    setPassword(e.target.value);
  }

  /** Check password & log in */
  function authenticate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!processing) {
      setIsError(false);
      setLabel('Zadejte přístupové heslo');
      setProcessing(true);
      // Send password to BE
      const formData = new FormData();
      formData.append('password', password);
      fetch(env.api + 'auth.php', {
        method: 'post',
        body: formData
      }).then(r => r.json()).then(response => {
        if (response.success) {
          localStorage.setItem('signinToken', response.token);
          setLoggedIn(true);
        } else {
          setTimeout(() => {
            setIsError(true);
            setLabel('Nesprávné heslo');
            setProcessing(false);
            setTimeout(() => passwordRef.current?.focus(), 0);
          }, 1000);
        }
      }).catch(e => {
        setIsError(true);
        setLabel('Došlo k chybě, zkuste to znovu');
        setProcessing(false);
        setTimeout(() => passwordRef.current?.focus(), 0);
        console.error(e);
      });
    }
  }

  return (
    <>
      <Loading isLoading={!isTokenChecked} isInit={true} />

      {!isLoggedIn &&
        <form className="login" onSubmit={authenticate}>

          <label className="row">
            <span className={isError ? 'error' : ''}>{label}</span>

            <input type={showPassword ? 'text' : 'password'} ref={passwordRef} id="password" name="password"
              value={password} onChange={handlePassChange} placeholder="Heslo" required disabled={processing} />

            {!!password.length && !showPassword &&
              <VisibleSVG
                title="Zobrazit heslo"
                onClick={() => setShowPassword(true)}
                onMouseDown={e => e.preventDefault()}
              />
            }
            {!!password.length && showPassword &&
              <HiddenSVG
                title="Skrýt heslo"
                onClick={() => setShowPassword(false)}
                onMouseDown={e => e.preventDefault()}
              />
            }
          </label>

          <button type="submit" disabled={processing}>
            {!processing && <span className="label">Odemknout</span>}
            {processing && <span className="processing">
              <span /><span /><span />
            </span>}
          </button>
        </form>
      }

      {isLoggedIn &&
        <Suspense fallback={<Loading isLoading={true} isInit={true} />}>
          <App />
        </Suspense>
      }
    </>
  );
}