import './Loading.scss';
// Interface
type LoadingProps = {
  isLoading: boolean;
  isInit?: boolean; // Hide initial transition on app load
};

export default function Loading(props: LoadingProps) {

  /** Props */
  const { isLoading, isInit } = props;

  return (
    <div className={`loading ${isLoading && 'show'} ${isInit && ' init'}`}>
      <div className="loading-ring" />
    </div>
  );
}
